import { motion } from "framer-motion";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { getCurrencyAmount, twoDecimalLocal } from "../../helpers/helpers";
import cn from "../../lib/cn";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import {
    opacity,
    translateContainer,
    wavyHeadings,
} from "../../shared/animation/constants/landingAnimation";
import SplitText from "../../shared/animation/typography/SplitText";
import H3 from "../../shared/typography/H3";
import P from "../../shared/typography/P";
import SubHeading from "../../shared/typography/SubHeading";

const min = 10000;
const max = 1000000;
const INITIAL_AMOUNT = 20000;

const EstimateSavings = () => {
    const [investment, setInvestment] = useState(INITIAL_AMOUNT);
    const [inputAmount, setInputAmount] = useState(INITIAL_AMOUNT);
    const [amountErr, setAmountErr] = useState(false);

    const getPosition = () => {
        let val = ((investment - min) / (max - min)) * 100;
        return `${val}%`;
    };
    const monthlyPayouts = useMemo(
        () => (investment / 100000) * 2650,
        [investment]
    );
    const totalPayouts = useMemo(() => monthlyPayouts * 60, [monthlyPayouts]);
    const solarAssetOwned = useMemo(() => investment / 55000, [investment]);

    const handleChangeInvestment = (e) => {
        let value = e.target.value;
        if (!isNaN(value) && max >= value && min <= value) {
            setInvestment(value);
            setInputAmount(value);
        }

        if (!isNaN(value) && (min > value || max < value)) {
            setAmountErr(true);
            setInputAmount(value);
        } else {
            setAmountErr(false);
        }
    };
    return (
        <Section className="pt-0 2xl:pt-6.4">
            <Container>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                >
                    <div className="w-full flex flex-col gap-0.8 mb-1.6">
                        <SplitText heading="h2" variants={wavyHeadings}>
                            Estimate your earnings
                        </SplitText>
                    </div>
                    <motion.div variants={opacity}>
                        <SubHeading>
                            Discover the ideal plan to fuel your investments{" "}
                        </SubHeading>
                    </motion.div>
                </motion.div>

                <div className="mt-4.8 rounded-m bg-custom-gradient p-0.1 overflow-hidden">
                    <div className="flex flex-wrap justify-between  bg-linear-bluish-white rounded-m p-1.6 md:p-2 xl:p-2.4">
                        {/* @DESC:: Slider @habib610 Mon September 09,2024 */}
                        <div className="w-full  xl:w-5/12">
                            <div className="flex gap-1 flex-wrap w-full items-center">
                                <div className="flex flex-wrap items-center justify-between w-full  gap-0.5 lg:gap-1 mb-1">
                                    <P className="w-full  md:flex-1 text-sm md:text-base xl:text-xl">
                                        How much do you plan on investing?
                                    </P>

                                    <div className="relative w-full md:w-auto">
                                        <span
                                            className={cn(
                                                ` absolute -bottom-0.4 -z-5 start-1
                                            w-0 h-0 
                                            border-l-[5px] border-l-transparent
                                            border-b-[6px] border-b-red/10
                                            border-r-[5px] border-r-transparent
                                            transition-all duration-300
                                            `,
                                                {
                                                    "opacity-0": !amountErr,
                                                    "opacity-100": amountErr,
                                                }
                                            )}
                                        ></span>

                                        <span
                                            className={cn(
                                                `text-sm text-red bg-red/10 block absolute start-0 end-0 px-0.4 rounded-s -bottom-2.4  flex-col z-20 transition-all duration-300`,
                                                {
                                                    "opacity-0": !amountErr,
                                                    "opacity-100": amountErr,
                                                }
                                            )}
                                        >
                                            Range is {getCurrencyAmount(min)} to{" "}
                                            {getCurrencyAmount(max)}
                                        </span>
                                        <input
                                            className={cn(
                                                "py-0.5 px-0.5 text-sm xl:text-base border-2 rounded-lg overflow-hidden w-full sm:w-[200px] outline-none border-primary"
                                            )}
                                            onChange={handleChangeInvestment}
                                            value={inputAmount}
                                        />
                                    </div>
                                </div>
                            </div>
                            <SliderWrapper
                                position={getPosition()}
                                className="w-full bg-white mt-1"
                            >
                                <div className="relative mt-1 sm:mt-1.2 mb-1.6 md:mb-2 xl:mb-3.2">
                                    <label
                                        htmlFor="labels-range-input"
                                        className="sr-only"
                                    >
                                        Labels range
                                    </label>

                                    <input
                                        id="labelsRangeInput"
                                        type="range"
                                        min={min}
                                        max={max}
                                        value={investment}
                                        // min={range.min}
                                        // max={range.max}
                                        // step={countStep()}
                                        onChange={handleChangeInvestment}
                                        className="w-full h-0.2 bg-primary rounded-lg appearance-none cursor-pointer"
                                    />
                                </div>
                            </SliderWrapper>
                            <div className="flex justify-between mb-1  md:mb-2.5 xl:mb-4 2xl:mb-5.6">
                                <P>{getCurrencyAmount(min)}</P>
                                <P>{getCurrencyAmount(max)}</P>
                            </div>
                            <P className=" text-gray-secondary text-sm xl:text-base">
                                *Terms and condition will apply
                            </P>
                        </div>
                        {/* @DESC:: Values @habib610 Mon September 09,2024 */}
                        <div className="w-full xl:w-6/12">
                            <div>
                                <div className="mt-2.4 xl:mt-0 flex xl:flex-wrap gap-y-1 xl:gap-y-1.6 gap-x-6.4">
                                    <div className="w-6/12 md:w-auto">
                                        <CalculatorItems
                                            name="Investment Tenure"
                                            value="5 Yrs"
                                        />
                                    </div>
                                    <div className="w-6/12 md:w-auto">
                                        <CalculatorItems
                                            name="Investment Return Rate"
                                            value="20%"
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-wrap gap-y-1.6 mt-1.6 xl:mt-4.8 gap-x-6.4">
                                    <CalculatorItems
                                        name="Monthly Payouts"
                                        value={`₹${getCurrencyAmount(
                                            monthlyPayouts
                                        )}`}
                                    />
                                    <CalculatorItems
                                        name="Total Payouts"
                                        value={`₹${getCurrencyAmount(
                                            totalPayouts
                                        )}`}
                                    />
                                    <CalculatorItems
                                        name="Solar Assets Owned"
                                        value={`${twoDecimalLocal(
                                            solarAssetOwned
                                        )}KWp`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default EstimateSavings;

const CalculatorItems = ({ name, value }) => {
    return (
        <div>
            <P className=" text-sm  mb-0.4">{name}</P>
            <H3 className="text-xl  xl:text-xxl">{value}</H3>
        </div>
    );
};

export const SliderWrapper = styled.div`
    position: relative;
    #labelsRangeInput::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        height: 30px;
        width: 35px;
        border-radius: 0.5rem;
        background: linear-gradient(78.73deg, #b9c6e9 -4.91%, #2963d1 47.13%);
        position: relative;
        ::after {
            position: absolute;
            content: "H";
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: green;
            z-index: 100;
        }
        cursor: pointer;
    }
`;
