import React from "react";
import cn from "../../lib/cn";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H1 from "../../shared/typography/H1";
import H2 from "../../shared/typography/H2";
import H3 from "../../shared/typography/H3";

const SrotBenefit = ({ containerClass }) => {
    return (
        <Section className="bg-black mb-0 lg:mb-0 xl:mb-0 2xl:mb-0">
            <Container>
                <div className={cn(containerClass)}>
                    <div className="flex flex-col gap-3 py-6 xl:py-[12rem] w-full xl:w-[80%]">
                        <p className="text-white border border-white text-center p-1 rounded-full w-36">
                            Why SROT?
                        </p>
                        <div className="flex gap-3 items-center">
                            <H2 className="text-white">Upto</H2>
                            <H1 className="text-white">20%</H1>
                            <H2 className="text-white">IRR</H2>
                        </div>
                        <div className="highlighted-text">
                            <H3 className="text-xxl">
                                <span>
                                    In publishing and graphic design, Lorem
                                    ipsum is a placeholder text commonly used to
                                    demonstrate the visual form of a document or
                                    a typeface without relying on meaningful
                                    content.Lorem ipsum may be used as a
                                    placeholder before the final copy is
                                    available.
                                </span>
                            </H3>
                        </div>
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default SrotBenefit;
