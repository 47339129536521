import PropTypes from "prop-types";
import React from "react";
import cn from "../../lib/cn";
/* @TODO => Fix styles @habib610 Fri September 06,2024 */
const baseStyles =
    "text-gray font-light xl:font-extralight text-2xl md:text-3xl xl:text-4xl font-sora";

const H2 = ({ className = "", children }) => {
    return <h2 className={cn(baseStyles, className)}>{children}</h2>;
};

export default H2;

H2.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
