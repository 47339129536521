import { motion } from "framer-motion";
import React, { useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import cn from "../../lib/cn";
import {
    insight1,
    insight2,
    insight3,
    insight4,
} from "../../resources/js/images";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import {
    slideOpacityRight,
    translateContainer,
    wavyHeadings,
} from "../../shared/animation/constants/landingAnimation";
import SplitText from "../../shared/animation/typography/SplitText";
import P from "../../shared/typography/P";
import InsightCard from "../../shared/ui/Cards/InsightCard";
import Tab from "../../shared/ui/tab/Tab";

/* @TODO => Unused component. Use once blogs are ready @habib610 Sun July 28,2024 */

const LatestPost = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentCategory, setCurrentCategory] = useState("");

    const cardData = [
        {
            id: 0,
            category: "Sustainability",
            title: "Exploring the Solar Revolution: A Sustainable Energy Journey",
            date: "March 20, 2024",
            imageUrl: insight1,
        },
        {
            id: 1,
            category: "Technology",
            title: "Harnessing Solar Power for a Brighter Future",
            date: "April 10, 2024",
            imageUrl: insight2,
        },
        {
            id: 2,
            category: "Trending",
            title: "Empowering Sustainability with Solar Energy Innovation",
            date: "March 20, 2024",
            imageUrl: insight3,
        },
        {
            id: 3,
            category: "Government Subsidy",
            title: "Stories from the Solar Frontier: Inspiring Renewable Energy Tales",
            date: "April 10, 2024",
            imageUrl: insight4,
        },
        {
            id: 4,
            category: "Solar Energy",
            title: "Exploring the Solar Revolution: A Sustainable Energy Journey",
            date: "March 20, 2024",
            imageUrl: insight2,
        },
        {
            id: 5,
            category: "Co2 Reduced",
            title: "Stories from the Solar Frontier: Inspiring Renewable Energy Tales",
            date: "April 10, 2024",
            imageUrl: insight3,
        },
    ];

    const tags = [
        "Trending",
        "Sustainability",
        "Technology",
        "Government Subsidy",
        "Solar Energy",
        "Co2 Reduced",
    ];

    const previousSlide = () => {
        setCurrentSlide((prev) =>
            prev === 0 ? cardData.length - 1 : prev - 1
        );
    };

    const nextSlide = () => {
        setCurrentSlide((prev) =>
            prev === cardData.length - 1 ? 0 : prev + 1
        );
    };

    const handleTabClick = (category) => {
        setCurrentCategory((prev) => (prev === category ? "" : category));
    };

    const filteredCards = currentCategory
        ? cardData.filter((card) => card.category === currentCategory)
        : cardData;

    return (
        <Section>
            <Container>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                    className="mb-2.4 overflow-x-hidden"
                >
                    <div className="w-full flex flex-col gap-0.8">
                        <SplitText heading="h2" variants={wavyHeadings}>
                            Fuel Your Hub with Srot Insights
                        </SplitText>
                    </div>
                    <motion.div variants={slideOpacityRight}>
                        <P className="text-gray-secondary">
                            Want to learn more about solar energy? Head to
                            Hypersot Blogs, where you can dive into an ocean of
                            knowledge and expertise about all things solar.
                        </P>
                    </motion.div>
                </motion.div>

                <div className="flex items-start sm:flex-col xl:flex-row lg:gap-1 flex-wrap">
                    <div className="flex flex-nowrap overflow-x-auto w-[80%] xl:w-[75%] gap-2.4 mb-2.4 no-scrollbar">
                        {tags.map((tag, i) => (
                            <Tab
                                key={i}
                                text={tag}
                                active={tag === currentCategory}
                                onClick={() => handleTabClick(tag)}
                            />
                        ))}
                    </div>

                    <a
                        href="#"
                        className={cn(
                            "text-gray-secondary text-sm opacity-0 underline",
                            "xl:opacity-100 lg:text-base ml-1 xl:mt-1"
                        )}
                    >
                        See all articles
                    </a>
                </div>

                {filteredCards.length > 3 ? (
                    <div>
                        <CarouselComponent
                            selectedItem={currentSlide}
                            onChange={setCurrentSlide}
                            showArrows={false}
                            showThumbs={false}
                            showStatus={false}
                            showIndicators={false}
                            autoPlay={true}
                            infiniteLoop={true}
                            interval={3000}
                            stopOnHover={true}
                            transitionTime={500}
                            swipeable={true}
                            preventMovementUntilSwipeScrollTolerance={true}
                            swipeScrollTolerance={50}
                            emulateTouch={true}
                            centerMode={true}
                            centerSlidePercentage={
                                document.body.clientWidth < 1279
                                    ? document.body.clientWidth < 1000
                                        ? document.body.clientWidth < 699
                                            ? 100
                                            : document.body.clientWidth < 767
                                            ? 80
                                            : 70
                                        : 48
                                    : document.body.clientWidth > 1535
                                    ? 33
                                    : 40
                            }
                        >
                            {filteredCards.map((card, index) => (
                                <InsightCard
                                    key={index}
                                    category={card.category}
                                    title={card.title}
                                    date={card.date}
                                    imageUrl={card.imageUrl}
                                    onActionClick={() =>
                                        alert(
                                            `Let's explore ${card.title} together!`
                                        )
                                    }
                                />
                            ))}
                        </CarouselComponent>
                    </div>
                ) : (
                    <div className="flex justify-between flex-wrap items-center mt-16">
                        {filteredCards.map((card, index) => (
                            <InsightCard
                                key={index}
                                category={card.category}
                                title={card.title}
                                date={card.date}
                                imageUrl={card.imageUrl}
                                onActionClick={() =>
                                    alert(
                                        `Let's explore ${card.title} together!`
                                    )
                                }
                            />
                        ))}
                    </div>
                )}
                <div
                    className={` mt-2 flex justify-end gap-1 w-full items-center`}
                >
                    <button
                        onClick={previousSlide}
                        className="text-xl xl:text-3xl 2xl:text-5xl"
                    >
                        <IoIosArrowBack />
                    </button>

                    <div className="flex items-end">
                        <span className="text-lg xl:text-3xl 2xl:text-5xl inline-block ">
                            {currentSlide + 1 > 9
                                ? currentSlide + 1
                                : `0${currentSlide + 1}`}
                        </span>{" "}
                        /
                        <span className=" inline-block text-sm xl:text-base 2xl:text-lg">
                            {cardData.length > 9
                                ? cardData.length
                                : `0${cardData.length}`}
                        </span>
                    </div>

                    <button
                        onClick={nextSlide}
                        className="text-xl xl:text-3xl 2xl:text-5xl"
                    >
                        <IoIosArrowForward />
                    </button>
                </div>
            </Container>
        </Section>
    );
};

export default LatestPost;
