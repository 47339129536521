import React from "react";
import BetterOpportunities from "./BetterOpportunities";
import ContactUSForm from "./ContactUSForm";
import CustomerReviews from "./CustomerReviews";
import EstimateSavings from "./EstimateSavings";
import Faq from "./Faq";
import HeroContent from "./Sections/HeroContent";
import InsightCarouselSection from "./Sections/InsightCarouselSection";
import SolarProjects from "./SolarProjects";
import SrotBenefit from "./SrotBenefit";

const Home = () => {
    return (
        <>
            <HeroContent />
            <SrotBenefit />
            <SolarProjects />
            <EstimateSavings />
            <BetterOpportunities />
            <CustomerReviews />
            <ContactUSForm />
            <InsightCarouselSection />
            <Faq />
        </>
    );
};

export default Home;
