import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
    IoIosArrowBack,
    IoIosArrowDown,
    IoIosArrowForward,
    IoIosArrowUp,
} from "react-icons/io";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import cn from "../../lib/cn";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import {
    translateContainer,
    wavyHeadings,
} from "../../shared/animation/constants/landingAnimation";
import SplitText from "../../shared/animation/typography/SplitText";
import ReviewItem from "./ReviewItem";

const data = [
    {
        id: 1,
        name: "Amar Dongare",
        date: "2 months ago",
        rate: 5,
        comment: `Really good service with highly professional team. Totally focused about their work. Totally appreciate the great team work which Hypersrot team have showcases in all the deliverables.`,
    },
    {
        id: 2,
        name: "Avi Rajput",
        date: "2 months ago",
        rate: 5,
        comment: `Incredible and excellent service..🖤 Keep going on..!! 👍`,
    },
    {
        id: 3,
        name: "Ashish Kumar",
        date: "2 months ago",
        rate: 5,
        comment: `Excellent service...keep it up`,
    },
    {
        id: 4,
        name: "Vivek Kumar",
        date: "2 months ago",
        rate: 5,
        comment: `Excellent service and good work 👍`,
    },
    {
        id: 5,
        name: "Nikhil Lohar",
        date: "2 months ago",
        rate: 5,
        comment: `Good service across india very nice`,
    },
    {
        id: 6,
        name: "Amlesh Kumar",
        date: "30 Apr 2024",
        rate: 5,
        comment: `Good service across india very nice
        `,
    },

    {
        id: 7,
        name: "Aadesh Jaiswal",
        date: "2 months ago",
        rate: 5,
        comment: `Excellent service`,
    },
    {
        id: 8,
        name: "Pragya Jais",
        date: "2 months ago",
        rate: 5,
        comment: `Good services.
        `,
    },
    {
        id: 9,
        name: "Vatsalya Aryan",
        date: "2 months ago",
        rate: 5,
        comment: `Great service`,
    },
    {
        id: 10,
        name: "Madhu kumari",
        date: "2 months ago",
        rate: 5,
        comment: `Excellent service`,
    },
    {
        id: 11,
        name: "Shwetabh Ranjan",
        date: "30 Apr 2024",
        rate: 5,
        comment: `Good service ✌🏼`,
    },
    {
        id: 12,
        name: "Saurav Srivastava",
        designation: "Goprotoz CEO",
        date: "2 months ago",
        rate: 5,
        comment: `Servise is mind blowing`,
    },
    {
        id: 13,
        name: "Sachin Kumar Ar",
        date: "2 months ago",
        rate: 5,
        comment: `Very beautiful place`,
    },

    {
        id: 14,
        name: "Raunit Jaiswal",
        date: "2 months ago",
        rate: 5,
        comment: `Good service`,
    },

    {
        id: 15,
        name: "Amit Gupta",
        date: "2 months ago",
        rate: 5,
        comment: `Awesome👍`,
    },
    {
        id: 16,
        name: "Anjali Raj",
        date: "2 months ago",
        rate: 5,
        comment: `Good`,
    },

    {
        id: 17,
        name: "Rahul Sinha",
        date: "2 months ago",
        rate: 5,
        comment: `Nice place`,
    },

    {
        id: 18,
        name: "Rahul Srivastava",
        date: "2 months ago",
        rate: 5,
        comment: `Good`,
    },

    {
        id: 19,
        name: "Samay Mishra XII C 18",
        date: "2 months ago",
        rate: 5,
        comment: ``,
    },

    {
        id: 20,
        name: "Jeet Singh",
        date: "2 months ago",
        rate: 5,
        comment: ``,
    },
    {
        id: 21,
        name: "Shivani kumari",
        date: "2 months ago",
        rate: 5,
        comment: ``,
    },
];
const container = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.1,
        },
    },
};
const CustomerReviews = () => {
    const [reviews, setReviews] = useState(data);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", () => {});
        return () => window.removeEventListener("resize", () => {});
    }, []);
    const previousSlide = () => {
        if (currentSlide === 0) {
            setCurrentSlide(data.length - 1);
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };

    const nextSlide = () => {
        if (currentSlide === data.length - 1) {
            setCurrentSlide(0);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const renderReviewsItem = (divisor) => {
        return reviews.map(
            (item, index) =>
                index % 3 === divisor && <ReviewItem data={item} key={index} />
        );
    };

    return (
        <Section>
            <Container>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                    className="flex flex-col  lg:flex-row justify-between items-start"
                >
                    <div className=" w-full flex flex-col gap-0.8 lg:w-[40%] xl:w-[40%] 2xl:w-[40%] mb-1 sm:mb-2 xl:mb-3">
                        <SplitText
                            variants={wavyHeadings}
                            heading="h2"
                            className=""
                        >
                            Hear from our satisfied customers
                        </SplitText>
                    </div>
                </motion.div>

                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={container}
                    className={cn("hidden xl:block relative ", {
                        "overscroll-y-auto h-auto": showMore,
                        "overflow-y-hidden max-h-[640px] 2xl:max-h-[700px] ":
                            !showMore,
                    })}
                >
                    <div className="grid grid-cols-1 xl:grid-cols-3 gap-2.4  xl:gap-4 h-auto">
                        <div className="flex flex-col gap-2.4  xl:gap-4">
                            {renderReviewsItem(0)}
                        </div>
                        <div className="flex flex-col gap-2.4  xl:gap-4">
                            {renderReviewsItem(1)}
                        </div>
                        <div className="flex flex-col gap-2.4  xl:gap-4">
                            {renderReviewsItem(2)}
                        </div>
                    </div>
                    <div
                        className={cn(
                            "absolute left-0 right-0  h-[500px] flex items-end justify-center transition-all duration-500  bg-opacity-100",
                            {
                                "bg-linear-bottom-overly bottom-0": !showMore,
                                "-bottom-4": showMore,
                            }
                        )}
                    >
                        <button
                            className={cn(
                                "text-primary text-xl 2xl:text-xl flex items-center gap-0.5 hover:cursor-pointer",
                                {
                                    "xl:mb-4 2xl:mb-5": !showMore,
                                    "-mt-5": !showMore,
                                }
                            )}
                            onClick={() => setShowMore((prev) => !prev)}
                        >
                            {showMore ? (
                                <>
                                    Show Less <IoIosArrowUp />
                                </>
                            ) : (
                                <>
                                    Show More <IoIosArrowDown />
                                </>
                            )}
                        </button>
                    </div>
                </motion.div>

                <div className=" mt-[3rem]  xl:hidden">
                    <CarouselComponent
                        selectedItem={currentSlide}
                        onChange={setCurrentSlide}
                        showArrows={false}
                        showStatus={false}
                        showIndicators={false}
                        showThumbs={false}
                        infiniteLoop={true}
                        interval={3000}
                        autoPlay={true}
                        stopOnHover={true}
                        transitionTime={500}
                        swipeable={true}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                        emulateTouch={true}
                        centerMode={true}
                        centerSlidePercentage={
                            document.body.clientWidth < 1279
                                ? document.body.clientWidth < 1100
                                    ? document.body.clientWidth < 766
                                        ? document.body.clientWidth < 630
                                            ? 100
                                            : 80
                                        : 60
                                    : 55
                                : document.body.clientWidth > 1535
                                ? 28
                                : 33
                        }
                    >
                        {reviews.map((item, index) => (
                            <ReviewItem data={item} key={index} />
                        ))}
                    </CarouselComponent>
                    <div
                        className={` flex justify-start gap-1 w-full items-center`}
                    >
                        <button
                            onClick={previousSlide}
                            className="text-xl xl:text-3xl 2xl:text-5xl"
                        >
                            <IoIosArrowBack />
                        </button>

                        <p>
                            <span className=" text-lg xl:text-3xl 2xl:text-5xl">
                                {currentSlide + 1 > 9
                                    ? currentSlide + 1
                                    : `0${currentSlide + 1}`}
                            </span>{" "}
                            <span className=" text-sm xl:text-base 2xl:text-lg">
                                /{" "}
                                {data.length > 9
                                    ? data.length
                                    : `0${data.length}`}
                            </span>
                        </p>

                        <button
                            onClick={nextSlide}
                            className="text-xl xl:text-3xl 2xl:text-5xl"
                        >
                            <IoIosArrowForward />
                        </button>
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default CustomerReviews;
