import React from "react";
import Hero from "./Hero";
import HeroDetails from "./HeroDetails";
import InsightCarouselSection from "./Insights";
import LatestPost from "./LatestPosts";

const Blogs = () => {
    return (
        <>
            <Hero
                containerClass={""}
                wrapperClass=" bg-blue-700"
                content={<HeroDetails />}
                url={""}
            />
            <InsightCarouselSection />
            <LatestPost />
        </>
    );
};

export default Blogs;
