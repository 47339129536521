import React from "react";

import cn from "../../lib/cn";
import Section from "../../shared/Section";
const Hero = ({ content, url, align, type, wrapperClass, containerClass }) => {
    return (
        <Section className={containerClass}>
            <div
                className={cn(
                    "bg-white relative h-[593px] sm:h-[993px]  xl:h-screen",
                    wrapperClass
                )}
            >
                <div
                    className={`absolute inset-x-0 inset-y-0 h-full flex ${
                        align ? align : "items-center"
                    } isolate ${wrapperClass}`}
                >
                    {content}
                </div>
            </div>
        </Section>
    );
};

export default Hero;
