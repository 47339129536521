import React from "react";
import Container from "../../shared/Container";
import H1 from "../../shared/typography/H1";
import P from "../../shared/typography/P";

const HeroDetails = () => {
    return (
        <Container className="text-center w-[70%]">
            <H1 className={"text-white"}>
                Made for your savvy Savings with Smart Idea
            </H1>
            <P className="text-white">
                SROT is an exclusive community that empowers the reliable to
                achieve financial growth.
            </P>
        </Container>
    );
};

export default HeroDetails;
